/* * {
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: chartreuse;
}

body {
  background-color: black;
}

.App-links {
  background-color: #282c34;
  min-height: 1vh;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: chartreuse;
}
.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: chartreuse;
}

.App-links {
  color: chartreuse;
  margin: 2%;
  padding: 1%;
  border: 1px solid grey;
}
.headerLink {
  margin: 2%;
  padding: 1%;
  color: chartreuse;
  text-decoration: none;
}

.homeBlurb {
  width: 60vw;

  margin: auto;
  color: chartreuse;
}
.boxes {
  margin-top: 10%;
  display: flex;
  flex-direction: horizontal;

  margin-left: 20%;
  height: 15vh;
  width: 15vw;
}
.boxes > img {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  max-height: 25vh;
  max-width: 25vw;
  border: 1px solid grey;
}
.codingBlog {
  color: chartreuse;
}

.postForm {
  display: flex;
  flex-direction: column;
} */

a {
  text-decoration: none;
}
.adminButtons {
  display: flex;
  flex-direction: column;
}
.adminButtons > * {
  margin-bottom: 5%;
}

/* for the crowd */
@keyframes raiseArms {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Arms raised */
  }
}

@keyframes lowerArms {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0); /* Arms lowered */
  }
}

.stick-figure {
  position: absolute;
  text-align: center;
  cursor: pointer;
  font-family: monospace;
  transition: transform 0.3s ease-in-out;
}

.stick-figure.raised {
  animation: raiseArms 0.3s ease-in-out forwards;
}

.stick-figure.lowered {
  animation: lowerArms 0.3s ease-in-out forwards;
}
